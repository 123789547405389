import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { appName } from '@/env';
import { dispatchPasswordRecovery } from '@/store/main/actions';
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.valid = true;
        this.username = '';
        this.appName = appName;
    }
    cancel() {
        this.$emit('cancel', false);
    }
    submit() {
        dispatchPasswordRecovery(this.$store, { username: this.username });
    }
};
Login = __decorate([
    Component
], Login);
export default Login;
